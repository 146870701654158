/* You can add global styles to this file, and also import other style files */

@import "../node_modules/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import '../node_modules/primeng/resources/themes/fluent-light/theme.css';
@import '../node_modules/primeng/resources/primeng.min.css';
@import '../node_modules/primeicons/primeicons.css';
@import "../node_modules/bootstrap/dist/css/bootstrap.css";
@import "~bootstrap/dist/css/bootstrap.css";

$dark-background: #2a2a2a;
$dark-word-color: #fff;

html {
    overflow-y: scroll;
  }

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.width100 {
    width: 100%;
}
a{
    text-decoration: none !important;
}
body {
    // font-family: 'Roboto', sans-serif !important;
    font-family: 'Montserrat', sans-serif !important;
    letter-spacing: 0.5px !important;
    margin: 0;
    padding: 0;
    background-color: #2a2a2a;
    color: #fff !important;
}

button {
    // font-family: 'Roboto', sans-serif !important;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 600 !important;
    letter-spacing: 0.5px !important;
    font-size: 14px !important;
}

p,
ul {
    margin: 0;
}


body::-webkit-scrollbar {
    width: 12px;
    cursor: pointer;
}

body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
    background-color: rgb(17, 17, 17);
    outline: 1px solid #1b1b1b;
    border-radius: 10px;
}

.main-btn {
    // background: #550000;
    height: 30px;
    min-width: 80px;
    line-height: 30px;
    padding: 0 15px;
    color: $dark-word-color;
    font-size: 14px;
    border: 0;
    border-radius: 4px;
    transition: 0.4s;
    background-color: #550000;

    &:hover {
        color: $dark-word-color;
        transform: translateY(-2px);
        box-shadow: 0 3px 6px rgb(0 0 0 / 20%);
        transition: 0.4s;
    }
}


.btn-group .dropdown-menu {
    min-width: 140px;
    margin-top: 5px;
    border: 0px;
    border-radius: 4px;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 20%);
    padding: 0;
}

.btn-group .dropdown-menu li {
    padding: 8px 10px;
    font-size: 14px;
    cursor: pointer;
}

.btn-group .dropdown-menu li:hover {
    background: #efefef;
}

.btn-group .dropdown-menu li a {
    display: block;
    text-decoration: none;
    color: #212529;
}


a {
    text-decoration: none;
}

// .carousel-item {
//     display: block !important;
//     // position: absolute;
//     opacity: 0;
//     transition: opacity 0.25s ease-in-out;
//     transition-delay: 0.25s;
// }

// .carousel-item.carousel-item-left,
// .carousel-item.carousel-item-right {
//     // position: relative;
//     opacity: 1;
// }

// .carousel-item.active {
//     z-index: 1;
//     opacity: 1; 
//     transition-delay: 0s;       
// }

// .carousel-item.active.carousel-item-left,
// .carousel-item.active.carousel-item-right {
//     opacity: 0;
//     transform: unset;
// }

// .carousel-item {
//     /* Force to always be block */
//     display: block !important;
//     z-index: 1;
//     // position: absolute;
//     transform: translateX(100%);
//     opacity: 0;
//     transition:all  0.7s;
//     visibility: hidden;
//   }

//   .carousel-item.active {
//     // position: relative;
//     transform: translateX(0);
//     visibility: visible ;
//     opacity: 1;
//   }

.carousel-item {
    display: block !important;
    z-index: 1;
    // position: absolute;
    opacity: 0;
    transition: opacity 0.8s ease-in-out, visibility 1s;
    visibility: hidden;
}

.carousel-item.active {
    // position: relative;
    opacity: 1;
    transition: opacity 0.8s ease-in-out, visibility 1s;
    visibility: visible;

}

ngb-carousel .carousel-inner .carousel-item .sr-only {
    display: none;
}

p-dialog {
    .p-dialog .p-dialog-header{
        background-color: transparent !important;
    }
    .p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover{
        background: transparent !important;
    }
}


ngb-carousel .carousel-indicators {
    margin: 0 !important;
    width: 100%;
    justify-content: start !important;
    bottom: 95px !important;
    padding-left: 85px !important;

    li {
        width: 10px;
        height: 10px;
        background: #fff;
        border-radius: 50px;
        opacity: .15;
        margin: 0 5px;
        cursor: pointer;
        list-style: none;
    }
}

ngb-carousel{
    .carousel-control-next, .carousel-control-prev {
        width: 4% !important;
        display: none;    
        .sr-only {
            display: none;
        }
    }
}

//   ngb-carousel  {
//       &:hover{
//           .carousel-control-next, .carousel-control-prev{
//             width: 4% !important;
//             display: none;
//             display: flex !important;
//             // background-image: linear-gradient(to left,black 70%, black);
//             transition: ease-in-out 0.3s;
//             .sr-only{
//                 display: none;
//             }
//           }
//     }
// }

p-dialog {
    .p-dialog-mask {
        background-color: #2d2e2fc4 !important;
    }

    .p-dialog {
        background: #000 !important;
        padding: 20px !important;
        border-radius: 13px;
        .p-dialog-header {
            justify-content: flex-end !important;
            padding: 0 !important;
        }
        .p-dialog-content{
            background: #000000 !important;
            color: #ffffff !important;
            padding: 0 !important;
        }
        .p-dialog-footer{
            background: #000000 !important;
            color: #ffffff !important;
            padding: 0 !important;
            text-align: left !important;
        }
        .p-dialog-header-icon {
            color: #fff;
        }
    }
}

.btn:focus {
    box-shadow: none !important;
}

p-dialog.cropper-modal {
    .p-dialog-mask {
        background-color: #2d2e2fc4 !important;
    }

    .p-dialog {
        background: rgb(255, 255, 255) !important;
        padding: 20px !important;
        border-radius: 10px;

        .p-dialog-header {
            justify-content: flex-end !important;
        }

        .p-dialog-header-icon {
            color: #000000;
        }
    }
}


.pi-chevron-right:before,
.pi-chevron-left:before {
    color: #fff;
}


.p-toast-message-icon {
    display: none;
    margin: 6px;
}

.p-toast {
    width: 300px !important;
}

.p-toast-message {
    border-radius: 5px !important;
    padding: 5px 10px;
    border: 1px solid !important;
    border-top: 3px solid !important;
}

.p-toast-message-success {
    color: #588337 !important;
    background-color: #dcfbc5 !important;
    border-color: #588337 !important;
}

.p-toast-message-error .p-toast-icon-close {
    color: #96091C;
}

.p-toast-message-error {
    color: #96091C !important;
    background-color: #FFE6EA !important;
    border-color: #96091C !important;
}

.p-toast-message-error .p-toast-icon-close {
    color: #96091C;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
    margin: 0px;
}

.main-carousal {
    .plyr {
        // height: 450px !important;
        // min-width: 130px !important;
        border-radius: 5px;

        video {
            height: 145% !important;
        }

        .plyr__control--overlaid {
            display: none !important;
        }
    }

    // .plyr video{

    // }
    .plyr--video .plyr__controls {
        padding: 0 !important;
        display: none !important;
    }
}

.disable {
    .plyr {
        // height: 450px !important;
        // min-width: 130px !important;
        border-radius: 5px;

        .plyr__control--overlaid {
            display: none !important;
        }
    }

    .plyr--video .plyr__controls {
        padding: 0 !important;
        display: none !important;
    }
}

.sidebarView {
    .plyr {
        height: 400px !important;
    }
}

.vedio-wrapper {
    .plyr {
        height: auto !important;
        min-width: 130px !important;
        border-radius: 5px;
        max-height: 710px;
        min-height: 600px;
    }

    .plyr--video .plyr__controls {
        padding: 0 !important;
    }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 2000px) and (-webkit-min-device-pixel-ratio: 1) {
    .vedio-wrapper {
        .plyr {
            max-height: 710px;
        }
    }
}

.plyr {
    height: auto !important;
    min-width: 130px !important;
    border-radius: 5px;
}

.plyr--video .plyr__controls {
    padding: 0 !important;
}

.main-footer {
    .footer-background {
        z-index: -1 !important;
    }

    .footer-shadow {
        // position: relative;
        z-index: -1 !important;
    }
}

.main-watch-live {
    .media-container {
        // background-color: black;
        z-index: 2 !important;
        position: relative;
    }
}

.main-program {
    z-index: 2 !important;
    position: relative;
}

ngx-stripe-card .field.StripeElement.StripeElement--empty {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    // padding: calc(0.5rem - 1px) calc(0.75rem - 1px) !important;
    text-align: inherit !important;
    box-sizing: border-box !important;
    // border: 1px solid #595959 !important;
    color: #fff !important;
    background: #fff !important;
    // border-radius: 8px !important;
    // background-color: #ffffff;
    border-radius: 4px !important;
    border: solid 2px #d6dde5;
    padding: 10px 15px !important;
}

ngx-stripe-card .field.StripeElement {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    // padding: calc(0.5rem - 1px) calc(0.75rem - 1px) !important;
    text-align: inherit !important;
    box-sizing: border-box !important;
    // border: 1px solid #595959 !important;
    color: #fff !important;
    background: #fff !important;
    // border-radius: 8px !important;
    // background-color: #ffffff;
    border-radius: 4px !important;
    border: solid 2px #d6dde5;
    padding: 10px 15px !important;
}

// input switch styling --------------------
.switch {
    position: relative;
    display: inline-block;
    width: 25px;
    height: 16px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
}

input:checked+.slider {
    background-color: #006aff;
}

input:focus+.slider {
    box-shadow: 0 0 1px #006aff;
}

input:checked+.slider:before {
    -webkit-transform: translateX(9px);
    -ms-transform: translateX(9px);
    transform: translateX(9px);
}


/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.p-tooltip.p-component.p-tooltip-bottom {
    background: #000;
    font-size: 12px;
    padding: 5px;
    border-radius: 6px;
}


@media screen and (min-width: 1380px) {

    ngb-carousel .carousel-indicators {
        // padding-left: 115px !important;
    }

}

@media screen and (min-width: 1480px) {

    ngb-carousel .carousel-indicators {
        padding-left: 200px !important;
    }
}

@media screen and (min-width:1660px) and (max-width: 1800px) {
    ngb-carousel .carousel-indicators {
        padding-left: 125px !important;
    }
}


@media screen and (min-width:1810px) and (max-width: 2000px) {
    ngb-carousel .carousel-indicators {
        padding-left: 230px !important;
    }
}


@media screen and (min-width:2100px) and (max-width: 3600px) {
    ngb-carousel .carousel-indicators {
        padding-left: 565px !important;
    }
}


@media screen and (min-width:1510px) and (max-width: 1650px) {
    ngb-carousel .carousel-indicators {
        padding-left: 70px !important;
    }
}

@media screen and (max-width: 650px) {
    ngb-carousel .carousel-indicators {
        top: 92%;
        left: 34%;

    }

    .vedio-wrapper .plyr {
        max-height: inherit !important;
        min-height: inherit !important;
    }

    html,
    body {
        overflow-x: hidden;
    }

    p-dialog .p-dialog {
        width: 70% !important;
    }

    ngb-carousel .carousel-caption {
        top: 70px !important;
    }

    .app-search {
        input {
            width: 100% !important;
            margin-left: 6px !important;
        }
    }

    .hide-logo {
        .top-header .header-container .logo img {
            display: none;
        }
    }

    .show-logo {
        .top-header .header-container .logo img {
            display: block;
        }
    }
}



@media only screen and (min-width:660px) and (max-width:961px) {
    ngb-carousel .carousel-indicators {
        margin: 0 !important;
        width: 100%;
        justify-content: start !important;
        bottom: 56px !important;
        padding-left: 45px !important;
    }

    .vedio-wrapper .plyr {
        min-height: 390px !important;
    }

    .menu-icon {
        display: block;
        padding: 0 20px;
        font-size: 25px;
    }

    .nav-bar {
        display: none !important;
    }

    .signin {
        display: none !important;
    }

    .nav-bar-mobile {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        ul {
            color: #ffffff;
            list-style: none;
            display: flex;
            flex-direction: column;
            min-height: 115px;
            padding: 0;
            background: #000000;
            /* padding: 20px; */
            width: 100%;
            padding-top: 10px;
            position: relative;
            opacity: 0.9;
            z-index: 999;

            .close {
                position: absolute;
                right: 20px;
                top: 20px;

                i {
                    font-size: 25px;
                    color: #b6b5b5;
                }
            }

            li {
                padding: 10px;
            }
        }
    }
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.vjs_video_3-dimensions {
    width: 100% !important;
}

.cursor-pointer {
    cursor: pointer;
}

.form-select {
    font-size: 1rem;
    line-height: 1.5rem;
    padding: calc(0.75rem - 1px) calc(0.75rem - 1px);
    text-align: inherit;
    box-sizing: border-box;
    border: 1px solid #e1dfdf;
    color: #424242;
    border-radius: 0.25rem;
}

.logo-position {
    .top-header .header-container .logo img {
        margin-left: 50px !important;
    }
}

@media screen and (max-width: 650px) {
    .logo-position .top-header .header-container .logo img{
        margin-left: 0 !important;
    }
}
.p-toast .p-toast-message.p-toast-message-Info{
    background: #e9e9ff;
    border: solid #696cff;
    border-width: 0 0 0 6px;
    color: #696cff;
}